import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import HeaderGeneric from '../components/HeaderGeneric'
import pic04 from '../assets/images/archetypes/MJ/new/senex.webp'
import Graph from '../components/graph'
import Mail from '../components/mail'
import MyVideo from '../components/video'

import More from '../components/more'
class Result extends React.Component {



  constructor(props) {

    super(props);
    console.log(props);


    this.state = this.props.location.state;



  }


  componentWillMount() {
    console.log("result page");
    console.log(this.state);
  }



  render() {
    let name = "Sage";
    return (
      <Layout>
        <Helmet title={name} />
        <HeaderGeneric text={name} subtext="Seeks the truth."/>
        <div id="main">
          <section id="content" className="main">
            <span className="image main"><img src={pic04} alt="" /></span>

            <h2>{name}</h2>
            
            <p>The Sage archetype may seem like a beacon of wisdom and enlightenment, but be warned, it is a double-edged sword. Those who do not fully integrate the Sage archetype risk being consumed by the relentless pursuit of truth and knowledge, sacrificing their relationships and personal well-being in the process. The Sage's obsession with objectivity and analysis can lead to a dangerous dogma, blinding them to the true nature of the world and themselves.
But there is hope. By embracing the Sage archetype with a clear and honest perspective, one can avoid the pitfalls of dogmatism and false wisdom. However, this is easier said than done. Many fall prey to the allure of the Sage's quest for truth, only to find themselves lost in the sands of falsehood. To avoid this fate, I recommend reading our e-book <a href={"https://jilecek.itch.io/the-inner-world-of-archetypes?utm_source=archetypes&utm_campaign=general"}>"The Inner World of Archetypes: A Guide to Integrating the 12 Jungian Archetypes"</a> which guides you through the pitfalls of the Sage archetype and helps you achieve true wisdom and clarity.
</p>
<More arch={name}/>
{this.state ? (
  <div>
    <Graph best={this.state.best}  data ={[
{

"id": this.state.names_arr[0],
"label": this.state.names_arr[0],
"value": this.state.vals_arr[0],
"color": "hsl(92, 70%, 50%)"
},
{
"id": this.state.names_arr[1],
"label": this.state.names_arr[1],
"value": this.state.vals_arr[1],
"color": "hsl(336, 70%, 50%)"
},
{
"id": this.state.names_arr[2],
"label": this.state.names_arr[2],
"value": this.state.vals_arr[2],
"color": "hsl(60, 70%, 50%)"
},
{
"id": this.state.names_arr[3],
"label": this.state.names_arr[3],
"value": this.state.vals_arr[3],
"color": "hsl(54, 70%, 50%)"
},
{
"id": this.state.names_arr[4],
"label": this.state.names_arr[4],
"value": this.state.vals_arr[4],
"color": "hsl(14, 70%, 50%)"
},
{

"id": this.state.names_arr[5],
"label": this.state.names_arr[5],
"value": this.state.vals_arr[5],
"color": "hsl(92, 70%, 50%)"
},
{
"id": this.state.names_arr[6],
"label": this.state.names_arr[6],
"value": this.state.vals_arr[6],
"color": "hsl(336, 70%, 50%)"
},
{
"id": this.state.names_arr[7],
"label": this.state.names_arr[7],
"value": this.state.vals_arr[7],
"color": "hsl(60, 70%, 50%)"
},
{
"id": this.state.names_arr[8],
"label": this.state.names_arr[8],
"value": this.state.vals_arr[8],
"color": "hsl(54, 70%, 50%)"
},
{
"id": this.state.names_arr[9],
"label": this.state.names_arr[9],
"value": this.state.vals_arr[9],
"color": "hsl(14, 70%, 50%)"
},
{

"id": this.state.names_arr[10],
"label": this.state.names_arr[10],
"value": this.state.vals_arr[10],
"color": "hsl(92, 70%, 50%)"
},
{
"id": this.state.names_arr[11],
"label": this.state.names_arr[11],
"value": this.state.vals_arr[11],
"color": "hsl(336, 70%, 50%)"
}
]}/>

<Mail best={this.state.best}/>
<MyVideo/>
</div>




) : (
  <div><i>Take the test on the <a href="/">main page</a> to see your archetype breakdown.</i>


  <br/>
<MyVideo/>

  </div>
)}
          </section>

        </div>
      </Layout>
    )
  }
}

export default Result
